@import 'normalize.css';
@import "~antd/dist/antd.css";
@font-face {
    font-family: 'ff-good-headline-web-pro-con';
    src: url('https://use.typekit.net/af/29ccbb/00000000000000000001134a/27/l?primer=e7c58b81a305e1fc09dd5591d751a34891b5b63ab278ac3b87ed8857fdd9b01a&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/29ccbb/00000000000000000001134a/27/d?primer=e7c58b81a305e1fc09dd5591d751a34891b5b63ab278ac3b87ed8857fdd9b01a&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/29ccbb/00000000000000000001134a/27/a?primer=e7c58b81a305e1fc09dd5591d751a34891b5b63ab278ac3b87ed8857fdd9b01a&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

body {
    font-family: "IBMPlexSans","ChevinPro","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
}
#webbot {
    margin: 0;
    font-family: ff-good-headline-web-pro-con, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4 !important;
    border: 1px solid #eee;
    box-sizing: border-box;
    @media only screen and (max-width: 479px) {
        font-size: 13px;
    }
    svg {
        vertical-align: unset;
    }
}
#webbot code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#webbot p {
    margin: 0;
    padding: 0;
}
#webbot {
    .app {
        &__container {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            @media (max-width: 767px) {
                flex-direction: column;
            }
        }
    }
    .graph {
        width: calc(100% - 430px);
        height: 70vh;
        @media (max-width: 767px) {
            flex-direction: column;
            width: 100%;
            height: 60vh;
        }
        &__echarts {
            height: 100%;
            width: 100%;
        }
    }
    .sidebar {
        flex-basis: 430px;
        background-color: #f5f5f5;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        overflow-y: auto;
        height: 70vh;
        @media (max-width: 767px) {
            height: 30vh;
        }
        &__title {
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 22px;
            line-height: 28px;
            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        &__button {
            font-family: ff-good-headline-web-pro-con, sans-serif !important;
            margin: 0 auto;
            margin-top: 30px;
            display: table !important;
            font-size: 24px !important;
            line-height: 30px !important;
            height: auto !important;
            text-transform: uppercase;
        }
    }
    .options {
        &-list {
            &__item {
                margin-top: 25px;
                &-title {
                    font-weight: bold;
                    text-align: center;
                    font-size: 24px;
                    margin-bottom: 15px;
                    @media (max-width: 767px) {
                        font-size: 20px;
                        margin-bottom: 15px;
                    }
                }
            }
            &__list {
                list-style: none;
                margin: 0;
                padding: 0;
                &-item {
                    background: #fff;
                    border: 1px solid #ddd;
                    padding: 10px 30px;
                    margin-bottom: 4px;
                    text-align: center;
                    position: relative;
                    font-size: 22px;
                    line-height: 26px;
                    @media (max-width: 767px) {
                        font-size: 18px;
                        line-height: 14px;
                    }
                    &-remove {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        cursor: pointer;

                        & span {
                        }
                    }
                }
            }
        }
    }
    .result {
        width: 100%;
        padding: 30px;
        &__title {
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 26px;
            line-height: 32px;
        }
        &__text {
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 26px;
            line-height: 32px;
        }
    }
    .ant-result {
        margin: 0 auto;
    }
    .ant-result-title {
        line-height: 1.4;
        @media screen and (max-width: 479px) {
            font-size: 18px;
            line-height: 1.4;
        }
    }
}
.ant-modal-title {
    font-family: ff-good-headline-web-pro-con, sans-serif !important;
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
}
.ant-modal .ant-btn {
    margin-right: 10px;
    margin-bottom: 10px;
    color: #fff;
    border: 0;
    &:hover {
        color: #fff;
    }
    &.btn-green {
        background: #61bd4f;
        &:hover {
            background: #58b446;
        }
    }
    &.btn-sky {
        background: #00c2e0;
        &:hover {
            background: #03b8d4;
        }
    }
    &.btn-red {
        background: #eb5a46;
        &:hover {
            background: #e55542;
        }
    }
    &.btn-orange {
        background: #ff9f1a;
        &:hover {
            background: #f19619;
        }
    }
    &.btn-gray {
        background: #b3bac5;
        &:hover {
            background: #a6afbd;
        }
    }
}
